import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import { scriptsService } from './apiService';
import { Script, Objection } from './apiService';
import { DEFAULT_SCRIPT } from './objectionDetection';

interface ScriptContextType {
  currentScript: Script | null;
  isLoading: boolean;
  error: string | null;
  detectObjection: (text: string) => Objection | null;
  refreshScript: () => Promise<void>;
}

// Create context with default values
const ScriptContext = createContext<ScriptContextType>({
  currentScript: null,
  isLoading: false,
  error: null,
  detectObjection: () => null,
  refreshScript: async () => {}
});

interface ScriptProviderProps {
  children: ReactNode;
}

export const ScriptProvider: React.FC<ScriptProviderProps> = ({ children }) => {
  const { user, isAuthenticated } = useAuth();
  const [currentScript, setCurrentScript] = useState<Script | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Function to load the script
  const loadScript = async () => {
    // Make sure user is authenticated and has a script assigned
    if (!isAuthenticated || !user) {
      // If not authenticated, use default script
      setCurrentScript(DEFAULT_SCRIPT as Script);
      return;
    }

    // Check if user has script property with an id
    const userScript = user?.script;
    if (!userScript?.id) {
      // If no script ID, use default script
      setCurrentScript(DEFAULT_SCRIPT as Script);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const script = await scriptsService.getScriptById(userScript.id);
      
      if (script) {
        // Ensure objections is an array even if undefined
        const scriptWithObjArray: Script = {
          ...script,
          objections: script.objections || []
        };
        setCurrentScript(scriptWithObjArray);
        console.log(`Loaded script: ${script.name} with ${script.objections?.length || 0} objections`);
      } else {
        // Fallback to default script if API fails
        setCurrentScript(DEFAULT_SCRIPT as Script);
        setError('Could not load assigned script, using default');
      }
    } catch (err) {
      console.error('Error loading script:', err);
      setCurrentScript(DEFAULT_SCRIPT as Script);
      setError('Error loading script, using default');
    } finally {
      setIsLoading(false);
    }
  };

  // Load the script when the user logs in
  useEffect(() => {
    loadScript();
  }, [isAuthenticated, user]);

  // Expose a function to manually refresh the script
  const refreshScript = async () => {
    await loadScript();
  };

  // Enhanced objection detection that handles pipe-delimited keywords
  const detectObjection = (text: string): Objection | null => {
    if (!currentScript?.objections?.length) {
      return null;
    }

    const lowerCaseText = text.toLowerCase();
    
    // Check each objection
    for (const objection of currentScript.objections) {
      // Handle pipe-delimited keywords
      if (objection.keyword.includes('|')) {
        // Split the keyword string into individual keywords
        const keywords = objection.keyword.split('|').map(k => k.trim());
        
        // Check each keyword
        for (const keyword of keywords) {
          if (keyword && lowerCaseText.includes(keyword.toLowerCase())) {
            return objection;
          }
        }
      } else {
        // Handle single keywords (original behavior)
        if (lowerCaseText.includes(objection.keyword.toLowerCase())) {
          return objection;
        }
      }
    }
    
    return null;
  };

  return (
    <ScriptContext.Provider
      value={{
        currentScript,
        isLoading,
        error,
        detectObjection,
        refreshScript
      }}
    >
      {children}
    </ScriptContext.Provider>
  );
};

// Custom hook to use the script context
export const useScript = () => useContext(ScriptContext);

export default ScriptContext;