import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute';
import AppWithHeader from './AppWithHeader';
import LoadingSpinner from './LoadingSpinner';
import UnauthorizedPage from './UnauthorizedPage';

// After login redirect handler
const AfterLoginRedirect: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  
  useEffect(() => {
    if (isAuthenticated) {
      const redirectPath = sessionStorage.getItem('redirectAfterLogin');
      if (redirectPath) {
        sessionStorage.removeItem('redirectAfterLogin');
        navigate(redirectPath);
      }
    }
  }, [isAuthenticated, navigate]);
  
  return null;
};

// Main routes component
const AppRoutes: React.FC = () => {
  const { loading } = useAuth();

  if (loading) {
    return <LoadingSpinner message="Verifying your credentials..." />;
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      
      {/* Protected routes */}
      <Route element={<ProtectedRoute />}>
        {/* Regular user routes */}
        <Route path="/" element={<AppWithHeader />} />
        
        {/* Admin-only routes */}
        <Route element={<ProtectedRoute requiredRole="admin" />}>
          <Route path="/admin/*" element={<div>Admin Panel</div>} />
        </Route>
        
        {/* Fallback route redirects to home */}
        <Route path="*" element={<AfterLoginRedirect />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;