import React from 'react';
import Header from './Header';
import App from './App';

const AppWithHeader: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="py-4">
        <App />
      </main>
    </div>
  );
};

export default AppWithHeader;