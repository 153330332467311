import { Objection, Script } from './apiService';

// Sample script with common sales objections (used as fallback)
export const DEFAULT_SCRIPT: Script = {
  id: 1,
  name: 'Default Sales Script',
  description: 'Generic sales script with common objections and rebuttals',
  objections: [
    {
      id: 1,
      keyword: 'expensive',
      category: 'pricing',
      rebuttal: "I understand budget concerns. Our solution provides an ROI within 3 months for most clients. Would it help to see our ROI calculator?"
    },
    {
      id: 2,
      keyword: 'not interested',
      category: 'general',
      rebuttal: "I understand. Many of our current clients initially felt the same way until they saw how we saved them 10 hours per week on average."
    },
    {
      id: 3,
      keyword: 'already have',
      category: 'competition',
      rebuttal: "That's great! Many of our clients switched from similar solutions when they realized our unique features could save them time and money."
    },
    {
      id: 4,
      keyword: 'call back later',
      category: 'timing',
      rebuttal: "I'd be happy to schedule a specific time that works better for you. Our calendar fills up quickly, so I'd like to secure a slot for you."
    },
    {
      id: 5,
      keyword: 'need to discuss',
      category: 'decision-making',
      rebuttal: "That makes sense. I can provide you with materials you can share with your team, and perhaps we can schedule a follow-up call that includes your colleagues?"
    },
    {
      id: 6,
      keyword: 'no budget',
      category: 'pricing',
      rebuttal: "I appreciate your budget constraints. Many clients find that our solution actually helps free up budget by reducing costs in other areas. Would you like to see some specific examples?"
    },
    {
      id: 7,
      keyword: 'bad timing',
      category: 'timing',
      rebuttal: "I understand timing is important. What specific concerns do you have about the timing? Our implementation is designed to be minimally disruptive."
    }
  ]
};

/**
 * Enhanced keyword-based objection detection that handles pipe-delimited keywords
 * This legacy function is kept for compatibility.
 * Use the ScriptContext's detectObjection function for real-time detection.
 */
export const detectObjection = (text: string, script: Script = DEFAULT_SCRIPT): Objection | null => {
  const lowerCaseText = text.toLowerCase();
  
  for (const objection of script.objections || []) {
    // Handle pipe-delimited keywords
    if (objection.keyword.includes('|')) {
      // Split the keyword string into individual keywords
      const keywords = objection.keyword.split('|').map(k => k.trim());
      
      // Check each keyword
      for (const keyword of keywords) {
        if (keyword && lowerCaseText.includes(keyword.toLowerCase())) {
          return objection;
        }
      }
    } else {
      // Handle single keywords (original behavior)
      if (lowerCaseText.includes(objection.keyword.toLowerCase())) {
        return objection;
      }
    }
  }
  
  return null;
};