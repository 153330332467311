import React from 'react';
import { useAuth } from './AuthContext';

const Header: React.FC = () => {
  const { user, logout, loading } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-6xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <h1 className="text-2xl text-gray-800" style={{ fontFamily: '"Roboto Mono", monospace', fontWeight: 500 }}>ALFRED_</h1>
          </div>
          {!loading && user && (
            <div className="flex items-center space-x-4">
              <div className="flex flex-col items-end">
                <div className="text-sm font-medium text-gray-700">
                  {user.name}
                </div>
                <div className="text-xs text-gray-500">
                  {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                </div>
              </div>
              <button
                onClick={handleLogout}
                disabled={loading}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {loading ? 'Signing out...' : 'Sign out'}
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;