import React, { useEffect, useRef, useState } from 'react';

type AudioLevelMeterProps = {
  stream: MediaStream | null;
  isRecording: boolean;
  onVolumeChange?: (volume: number) => void;
};

const AudioLevelMeter: React.FC<AudioLevelMeterProps> = ({ stream, isRecording, onVolumeChange }) => {
  const [volume, setVolume] = useState(0);
  const animationRef = useRef<number | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  
  useEffect(() => {
    if (!stream || !isRecording) {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      setVolume(0);
      return;
    }
    
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);
    
    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;
    
    const updateVolume = () => {
      if (!analyserRef.current || !dataArrayRef.current) return;
      
      analyserRef.current.getByteFrequencyData(dataArrayRef.current);
      
      // Calculate average volume
      let sum = 0;
      for (let i = 0; i < dataArrayRef.current.length; i++) {
        sum += dataArrayRef.current[i];
      }
      const avg = sum / dataArrayRef.current.length;
      
      // Normalize to 0-100
      const normalizedVolume = Math.min(100, Math.max(0, avg * 1.5));
      setVolume(normalizedVolume);
      
      // Report volume change if callback provided
      if (onVolumeChange) {
        onVolumeChange(normalizedVolume);
      }
      
      animationRef.current = requestAnimationFrame(updateVolume);
    };
    
    updateVolume();
    
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      audioContext.close();
    };
  }, [stream, isRecording, onVolumeChange]);
  
  // Calculate color based on volume
  const getColor = () => {
    if (volume < 30) return 'bg-green-500';
    if (volume < 70) return 'bg-yellow-500';
    return 'bg-red-500';
  };
  
  // Get appropriate volume status message
  const getVolumeStatus = () => {
    if (!isRecording) return <span className="text-gray-500">Microphone inactive</span>;
    
    if (volume < 10) {
      return <span className="text-red-500">Very low audio level detected. Please check your microphone.</span>;
    } else if (volume < 30) {
      return <span className="text-yellow-500">Low audio level. Consider speaking louder.</span>;
    } else if (volume >= 80) {
      return <span className="text-orange-500">Audio level high. Consider moving microphone further.</span>;
    } else {
      return <span className="text-green-500">Volume OK</span>;
    }
  };
  
  return (
    <div className="w-full max-w-md" style={{minHeight: '50px'}}>
      <div className="flex items-center space-x-2">
        <div className="text-xs w-16 text-gray-500">
          {isRecording ? `${Math.round(volume)}%` : 'Inactive'}
        </div>
        <div className="flex-1 bg-gray-200 h-2 rounded-full overflow-hidden">
          <div 
            className={`h-full ${getColor()} transition-all duration-100`}
            style={{ width: `${volume}%` }}
          ></div>
        </div>
      </div>
      <div className="h-6 mt-1 text-xs">
        {getVolumeStatus()}
      </div>
    </div>
  );
};

export default AudioLevelMeter;