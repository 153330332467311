import React, { useEffect, useRef } from 'react';

export type TranscriptSegment = {
  id: string;
  text: string;
  timestamp: string;
  speaker: 'agent' | 'customer';
  isObjection: boolean;
  isInterim?: boolean;
};

type TranscriptPanelProps = {
  segments: TranscriptSegment[];
  interimSegments: TranscriptSegment[];
  isRecording: boolean;
};

const TranscriptPanel: React.FC<TranscriptPanelProps> = ({ segments, interimSegments, isRecording }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Function to get speaker-specific styling
  const getSpeakerClass = (speaker: 'agent' | 'customer') => {
    if (speaker === 'customer') {
      return 'bg-gray-100 border-l-2 border-blue-500'; // Customer styling
    } else {
      return 'bg-blue-50 border-l-2 border-green-500'; // Agent styling
    }
  };
  
  // Auto-scroll to the bottom when new segments arrive
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [segments, interimSegments]);
  
  // Filter out empty segments
  const nonEmptySegments = segments.filter(segment => segment.text.trim() !== '');
  
  // Helper function to extract timestamp from ID
  const getTimestampFromId = (id: string): number => {
    // Extract the timestamp part from the ID
    const matches = id.match(/\d+/);
    if (matches && matches[0]) {
      return parseInt(matches[0], 10);
    }
    return 0;
  };

  // Sort segments by timestamp for chronological order
  nonEmptySegments.sort((a, b) => {
    return getTimestampFromId(a.id) - getTimestampFromId(b.id);
  });
  
  // Create one interim segment per speaker (agent/customer)
  const agentInterim = interimSegments
    .filter(segment => segment.speaker === 'agent' && segment.text.trim() !== '')
    .sort((a, b) => {
      // Sort by timestamp, most recent first
      return getTimestampFromId(b.id) - getTimestampFromId(a.id);
    })[0]; // Take only the most recent one
  
  const customerInterim = interimSegments
    .filter(segment => segment.speaker === 'customer' && segment.text.trim() !== '')
    .sort((a, b) => {
      // Sort by timestamp, most recent first
      return getTimestampFromId(b.id) - getTimestampFromId(a.id);
    })[0]; // Take only the most recent one
    
  // Combine all segments for display
  const displaySegments = [...nonEmptySegments];
  
  // Add interim segments if they exist and have content
  if (agentInterim && agentInterim.text.trim() !== '') displaySegments.push(agentInterim);
  if (customerInterim && customerInterim.text.trim() !== '') displaySegments.push(customerInterim);
  
  // Final sort by timestamp
  displaySegments.sort((a, b) => {
    return getTimestampFromId(a.id) - getTimestampFromId(b.id);
  });
  
  return (
    <div className="border rounded-lg shadow-sm p-4 bg-white h-96 overflow-y-auto" ref={containerRef}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Live Transcription</h2>
        {isRecording && (
          <div className="flex items-center">
            <div className="w-3 h-3 bg-red-500 rounded-full recording-indicator mr-2"></div>
            <span className="text-sm text-gray-600">Recording</span>
          </div>
        )}
      </div>
      
      <div className="space-y-3">
        {displaySegments.length === 0 ? (
          <p className="text-gray-500 italic">Waiting for speech...</p>
        ) : (
          displaySegments.map(segment => (
            <div 
              key={segment.id}
              className={`p-3 rounded-lg ${getSpeakerClass(segment.speaker)} ${
                segment.isObjection 
                  ? 'border-l-4 border-red-500' 
                  : ''
              } ${
                segment.isInterim
                  ? 'border border-dashed border-gray-300'
                  : ''
              }`}
            >
              <div className="flex justify-between text-xs text-gray-500 mb-1">
                <span className="font-medium">{segment.speaker === 'customer' ? 'Customer' : 'Agent'}</span>
                <div className="flex items-center">
                  {segment.isInterim && (
                    <span className="mr-2 px-1 py-0.5 bg-gray-200 text-gray-600 text-xs rounded">typing...</span>
                  )}
                  <span>{segment.timestamp}</span>
                </div>
              </div>
              <p className={segment.isInterim ? 'text-gray-500 italic' : ''}>
                {segment.text}
              </p>
              {segment.isObjection && !segment.isInterim && (
                <div className="mt-1 text-xs text-red-600 font-medium">
                  Objection detected
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TranscriptPanel;