import axios from 'axios';
import jwtDecode from 'jwt-decode';

// Token interfaces
interface JwtPayload {
  exp: number;
  iat: number;
  iss: string;
  aud: string;
  sub: string;
  id: number;
  username: string;
  name: string;
  role: string;
  script_id: number | null;
}

// User interface with script information
export interface User {
  id: number;
  username: string;
  name: string;
  role: string;
  script?: {
    id: number;
    name: string;
    description?: string;
  };
}

// Script and objection interfaces
export interface Objection {
  id: number;
  keyword: string;
  category?: string;
  rebuttal: string;
}

export interface Script {
  id: number;
  name: string;
  description?: string;
  objections?: Objection[];
}

// Create an axios instance with default config
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5001/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

// Check if token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    const currentTime = Date.now() / 1000;
    
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Token decode error:', error);
    return true;
  }
};

// Add a request interceptor to add auth token to all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    
    if (token) {
      // Check if token is expired before adding it
      if (isTokenExpired(token)) {
        console.log('Token expired, removing from storage');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_user');
        
        // Only redirect if not already on login page
        if (!window.location.pathname.includes('/login')) {
          window.location.href = '/login';
          throw new axios.Cancel('Token expired. Redirecting to login.');
        }
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token issues
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Don't handle cancelled requests
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    
    // Extract error message if available
    let errorMessage = 'An error occurred';
    
    if (axios.isAxiosError(error) && error.response) {
      // Handle authentication errors
      if (error.response.status === 401 || error.response.status === 403) {
        console.log(`Auth error: ${error.response.status}`);
        
        // Clear auth data
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_user');
        
        // Only redirect if not already on login page
        if (!window.location.pathname.includes('/login')) {
          window.location.href = '/login';
        }
      }
      
      // Extract error message from response
      errorMessage = error.response.data?.error?.message || 
                    error.response.data?.message || 
                    error.message || 
                    'An error occurred';
    }
    
    // Add the error message to the error object for easier access
    const enhancedError = error as any;
    enhancedError.userMessage = errorMessage;
    
    return Promise.reject(enhancedError);
  }
);

// Export authentication service
export const authService = {
  // Login user
  login: async (username: string, password: string) => {
    try {
      const response = await api.post('/auth/login', { username, password });
      const { token, user } = response.data;
      
      if (!token || !user) {
        return { success: false, error: 'Invalid response from server' };
      }
      
      // Store token and user info
      localStorage.setItem('auth_token', token);
      localStorage.setItem('auth_user', JSON.stringify(user));
      
      return { success: true, user };
    } catch (error: any) {
      console.error('Login error:', error);
      const message = error.userMessage || 'Login failed';
      return { success: false, error: message };
    }
  },
  
  // Logout user
  logout: async () => {
    try {
      // Try to call logout endpoint if authenticated
      if (localStorage.getItem('auth_token')) {
        await api.post('/auth/logout');
      }
    } catch (error) {
      console.error('Logout API error:', error);
    } finally {
      // Always clear local storage on logout
      localStorage.removeItem('auth_token');
      localStorage.removeItem('auth_user');
    }
  },
  
  // Get current user info
  getCurrentUser: async (): Promise<User | null> => {
    try {
      const response = await api.get('/auth/me');
      return response.data.user;
    } catch (error) {
      console.error('Get current user error:', error);
      return null;
    }
  },
  
  // Check if user is authenticated
  isAuthenticated: (): boolean => {
    const token = localStorage.getItem('auth_token');
    if (!token) return false;
    
    // Check if token is valid and not expired
    return !isTokenExpired(token);
  },
  
  // Get stored user data
  getStoredUser: (): User | null => {
    const userData = localStorage.getItem('auth_user');
    if (!userData) return null;
    
    try {
      return JSON.parse(userData);
    } catch (error) {
      console.error('Error parsing stored user:', error);
      return null;
    }
  }
};

// Scripts and objections service
export const scriptsService = {
  // Get all scripts
  getAllScripts: async (): Promise<Script[]> => {
    try {
      const response = await api.get('/scripts');
      return response.data.scripts || [];
    } catch (error) {
      console.error('Error getting scripts:', error);
      return [];
    }
  },
  
  // Get script by ID with objections
  getScriptById: async (scriptId: number): Promise<Script | null> => {
    try {
      const response = await api.get(`/scripts/${scriptId}`);
      return response.data.script || null;
    } catch (error) {
      console.error(`Error getting script ${scriptId}:`, error);
      return null;
    }
  },
  
  // Detect objection in text
  detectObjection: async (text: string, scriptId: number): Promise<Objection | null> => {
    try {
      const response = await api.post('/detect-objection', { text, scriptId });
      return response.data.objection || null;
    } catch (error) {
      console.error('Error detecting objection:', error);
      return null;
    }
  }
};

// Export the API instance as default
export default api;