import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import AppRoutes from './AppRoutes';
import { AuthProvider } from './AuthContext';
import { ScriptProvider } from './ScriptContext';

// Add the required TypeScript interfaces for the Web Speech API
declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ScriptProvider>
          <AppRoutes />
        </ScriptProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);