import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import LoadingSpinner from './LoadingSpinner';

interface ProtectedRouteProps {
  redirectPath?: string;
  requiredRole?: string | string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  redirectPath = '/login',
  requiredRole
}) => {
  const { isAuthenticated, user, loading } = useAuth();
  const location = useLocation();

  // Show loading spinner while checking authentication
  if (loading) {
    return <LoadingSpinner message="Verifying your credentials..." />;
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    // Save the attempted URL for redirecting after login
    const currentPath = location.pathname + location.search;
    sessionStorage.setItem('redirectAfterLogin', currentPath);
    
    return <Navigate to={redirectPath} replace />;
  }

  // If role check is required and user doesn't have the required role
  if (requiredRole && user) {
    const hasRequiredRole = Array.isArray(requiredRole) 
      ? requiredRole.includes(user.role)
      : user.role === requiredRole;
      
    if (!hasRequiredRole) {
      return <Navigate to="/unauthorized" replace />;
    }
  }

  // User is authenticated and has required role, render the protected route
  return <Outlet />;
};

export default ProtectedRoute;