import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import TranscriptPanel, { TranscriptSegment } from './TranscriptPanel';
import SuggestionsPanel from './SuggestionsPanel';
import AudioLevelMeter from './AudioLevelMeter';
import { Objection } from './apiService';
import { useScript } from './ScriptContext';

// Define types for our application
type AudioDeviceInfo = {
  deviceId: string;
  label: string;
  kind: string;
};

type RecognitionError = {
  type: string;
  message: string;
  timestamp: number;
};

const App: React.FC = () => {
  // State management
  const [audioDevices, setAudioDevices] = useState<AudioDeviceInfo[]>([]);
  const [selectedMicId, setSelectedMicId] = useState<string>('');
  const [selectedSpeakerId, setSelectedSpeakerId] = useState<string>('');
  const [isRecording, setIsRecording] = useState(false);
  const [transcriptSegments, setTranscriptSegments] = useState<TranscriptSegment[]>([]);
  const [interimSegments, setInterimSegments] = useState<{[key: string]: TranscriptSegment}>({});
  const [currentObjection, setCurrentObjection] = useState<Objection | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [status, setStatus] = useState('Ready to set up your call assistant');
  const [recognitionErrors, setRecognitionErrors] = useState<RecognitionError[]>([]);
  const [recognitionActive, setRecognitionActive] = useState(false);
  const [showMacSetup, setShowMacSetup] = useState(false);
  const [showWindowsSetup, setShowWindowsSetup] = useState(false);
  
  // Script context
  const { detectObjection } = useScript();

  // References for both audio streams
  const micStreamRef = useRef<MediaStream | null>(null);
  const speakerStreamRef = useRef<MediaStream | null>(null);
  const micRecorderRef = useRef<MediaRecorder | null>(null);
  const speakerRecorderRef = useRef<MediaRecorder | null>(null);
  const recognitionAgentRef = useRef<any>(null);
  const recognitionCustomerRef = useRef<any>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const timerRef = useRef<number | null>(null);
  const restartAttempts = useRef<number>(0);

  // Initialize SpeechRecognition with browser compatibility
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  // Effect for loading available audio devices
  useEffect(() => {
    const loadAudioDevices = async () => {
      try {
        // Request permission to access audio devices
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        // Stop the stream immediately, we just needed permission
        stream.getTracks().forEach(track => track.stop());
        
        // Get list of audio devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        
        // Filter microphones and input devices
        const inputDevices = devices
          .filter(device => device.kind === 'audioinput')
          // Don't filter out any devices to ensure we see all options
          .map(device => ({
            deviceId: device.deviceId,
            label: device.label || `Audio Input ${device.deviceId.substring(0, 5)}`,
            kind: 'audioinput'
          }));
        
        // Filter output devices
        const outputDevices = devices
          .filter(device => device.kind === 'audiooutput')
          .map(device => ({
            deviceId: device.deviceId,
            label: device.label || `Audio Output ${device.deviceId.substring(0, 5)}`,
            kind: 'audiooutput'
          }));
        
        setAudioDevices([...inputDevices, ...outputDevices]);
        
        // Select default microphone device with priority: BlackHole 2ch > VB-Cable > first device
        if (inputDevices.length > 0) {
          const blackholeDevice = inputDevices.find(device => device.label.includes('BlackHole 2ch'));
          const vbCableDevice = inputDevices.find(device => device.label.includes('VB-Cable'));
          
          if (blackholeDevice) {
            setSelectedMicId(blackholeDevice.deviceId);
          } else if (vbCableDevice) {
            setSelectedMicId(vbCableDevice.deviceId);
          } else {
            setSelectedMicId(inputDevices[0].deviceId);
          }
        }
        
        if (outputDevices.length > 0) {
          setSelectedSpeakerId(outputDevices[0].deviceId);
        }
        
        setStatus('Audio devices loaded. Ready to record.');
      } catch (err) {
        console.error('Error accessing media devices:', err);
        setStatus(`Error: ${err instanceof Error ? err.message : 'Could not access microphone'}`);
      }
    };

    loadAudioDevices();
    
    // Cleanup on unmount
    return () => {
      // Capture current values of refs for cleanup
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const micStream = micStreamRef.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const speakerStream = speakerStreamRef.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const agentRecognition = recognitionAgentRef.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const customerRecognition = recognitionCustomerRef.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const audioCtx = audioContextRef.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const timer = timerRef.current;
      
      if (micStream) {
        micStream.getTracks().forEach(track => track.stop());
      }
      
      if (speakerStream) {
        speakerStream.getTracks().forEach(track => track.stop());
      }
      
      if (agentRecognition) {
        try {
          agentRecognition.stop();
        } catch (e) {
          // Ignore errors on cleanup
        }
      }
      
      if (customerRecognition) {
        try {
          customerRecognition.stop();
        } catch (e) {
          // Ignore errors on cleanup
        }
      }
      
      if (audioCtx) {
        try {
          audioCtx.close();
        } catch (e) {
          // Ignore errors on cleanup
        }
      }
      
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  // Check for objections in the transcript
  const checkForObjections = (text: string): Objection | null => {
    return detectObjection(text);
  };

  // Start speech recognition for customer's microphone
  const startAgentSpeechRecognition = async () => {
    if (!SpeechRecognition) {
      setStatus('Speech recognition not supported in this browser. Try Chrome for best results.');
      return false;
    }

    try {
      // IMPORTANT: We need to make sure Chrome is actually using our selected device
      // Ensure we have an active MediaStream before initializing SpeechRecognition
      if (!micStreamRef.current) {
        setStatus('Error: No audio stream available. Please check your device selection.');
        return false;
      }
      
      // Force Chrome to use the already established stream with our selected device
      // Force the Audio Context to be active
      const audioContext = audioContextRef.current;
      if (audioContext && audioContext.state !== 'running') {
        await audioContext.resume();
      }
      
      // Short delay to ensure audio context is fully active
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Now initialize SpeechRecognition
      const recognition = new SpeechRecognition();
      recognitionAgentRef.current = recognition;
      
      // Configure recognition settings
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'en-US';
      recognition.maxAlternatives = 1;
      
      // Handle recognition results
      recognition.onresult = (event: any) => {
        setRecognitionActive(true);
        restartAttempts.current = 0;
        
        // Create a timestamp-based session ID for customer
        const sessionId = Date.now().toString() + '-customer-session';
        
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const resultIndex = i;
          const transcript = event.results[i][0].transcript;
          const isFinal = event.results[i].isFinal;
          
          const resultId = `${sessionId}-${resultIndex}`;
          const timestamp = new Date().toLocaleTimeString();
          
          handleRecognitionResult(resultId, transcript, isFinal, timestamp, 'customer');
        }
      };
      
      // Handle recognition errors - temporarily disabled agent-side error handling
      recognition.onerror = (event: any) => {
        console.log('Speech recognition notification:', event.error);
        // No error handling for now, just log to console
        
        // For serious errors like permission denial, still show status
        if (event.error === 'not-allowed') {
          setStatus(`Error: Microphone permission denied. Please allow access.`);
          setRecognitionActive(false);
        } else if (event.error === 'audio-capture') {
          setStatus(`Error: No microphone detected. Please check your connection.`);
          setRecognitionActive(false);
        }
        // We're ignoring 'no-speech' errors as requested
      };
      
      // Handle when recognition ends
      recognition.onend = () => {
        console.log('Speech recognition ended');
        
        // Auto-restart if we're still supposed to be recording
        if (isRecording) {
          try {
            recognition.start();
            console.log('Recognition restarted automatically');
            setRecognitionActive(true);
          } catch (e) {
            console.error('Could not restart recognition', e);
            setRecognitionActive(false);
          }
        }
      };
      
      // Start the recognition
      recognition.start();
      setRecognitionActive(true);
      console.log('Speech recognition started');
      return true;
    } catch (err) {
      console.error('Error initializing speech recognition:', err);
      setStatus(`Speech recognition error: ${err instanceof Error ? err.message : 'Unknown error'}`);
      setRecognitionActive(false);
      return false;
    }
  };

  // Generic handler for recognition results from either channel
  const handleRecognitionResult = (resultId: string, transcript: string, isFinal: boolean, timestamp: string, speaker: 'agent' | 'customer') => {
    // Create standardized timestamp for sorting
    const sortableTimestamp = Date.now();
    
    // Force speaker identification based on source
    if (resultId.includes('-agent-')) {
      speaker = 'agent';
    } else if (resultId.includes('-customer-')) {
      speaker = 'customer';
    }
    
    // Skip empty transcripts
    if (transcript.trim() === '') {
      return;
    }
    
    if (isFinal) {
      // This is a final result
      const newSegment: TranscriptSegment = {
        id: `final-${sortableTimestamp}-${resultId}`,
        text: transcript,
        timestamp,
        speaker,
        isObjection: false,
        isInterim: false
      };
      
      // Check for objections only in customer segments
      if (speaker === 'customer') {
        const objection = checkForObjections(transcript);
        if (objection) {
          newSegment.isObjection = true;
          setCurrentObjection(objection);
        }
      }
      
      // Add the final segment
      setTranscriptSegments(prev => [...prev, newSegment]);
      console.log(`Final transcript (${speaker}):`, transcript);
      
      // Clean up interim segments from the same speaker
      setInterimSegments(prev => {
        const updated = { ...prev };
        // Remove all interim segments from this speaker
        Object.keys(updated).forEach(key => {
          if (updated[key].speaker === speaker) {
            delete updated[key];
          }
        });
        return updated;
      });
    } else {
      // This is an interim result
      const interimSegment: TranscriptSegment = {
        id: `interim-${sortableTimestamp}-${resultId}`,
        text: transcript,
        timestamp,
        speaker,
        isObjection: false,
        isInterim: true
      };
      
      // For customer speaker, check for potential objections in interim results
      if (speaker === 'customer') {
        const potentialObjection = checkForObjections(transcript);
        if (potentialObjection) {
          interimSegment.isObjection = true;
          // Don't set currentObjection yet as this is interim
        }
      }
      
      // Store interim segment
      setInterimSegments(prev => {
        const updated = { ...prev };
        // Add new interim segment
        updated[`${speaker}-latest`] = interimSegment;
        return updated;
      });
      
      console.log(`Interim transcript (${speaker}):`, transcript);
    }
  };

  // Start recording and speech recognition from both channels
  const startRecording = async () => {
    try {
      if (!selectedMicId) {
        setStatus('Error: Please select a microphone device');
        return;
      }

      // Reset error state
      setRecognitionErrors([]);
      restartAttempts.current = 0;

      // Start capturing microphone audio - THIS IS CRITICAL FOR WEB SPEECH API
      // Web Speech API will use the browser's default device, so we need to make sure
      // our selected device becomes the browser's active audio input before we start recognition
      const micConstraints = {
        audio: {
          deviceId: { exact: selectedMicId },
          echoCancellation: false,  // Turn off echo cancellation for better recognition
          noiseSuppression: false,   // Turn off noise suppression for better quality
          autoGainControl: false,    // Turn off auto gain to preserve audio levels
          channelCount: 2            // Ensure we capture both channels
        }
      };

      // Get access to the microphone - this makes it the active input for the browser
      const micStream = await navigator.mediaDevices.getUserMedia(micConstraints);
      micStreamRef.current = micStream;
      
      // Create audio context with specific options for Windows compatibility
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)({
        latencyHint: 'interactive',
        sampleRate: 44100,
      });
      audioContextRef.current = audioContext;

      // Log the actual device being used for debugging
      console.log("Using device ID:", selectedMicId);
      console.log("Actual stream track settings:", micStream.getAudioTracks()[0].getSettings());
      setStatus(`Using device: ${micStream.getAudioTracks()[0].label} (ID: ${selectedMicId.substring(0, 8)}...)`);

      // Create an analyzer node to ensure audio is actively processed
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;

      // Create source from our selected device's stream
      const source = audioContext.createMediaStreamSource(micStream);
      source.connect(analyser);
      
      // Create a script processor node to keep the audio context active without feeding back to speakers
      const processor = audioContext.createScriptProcessor(1024, 1, 1);
      analyser.connect(processor);
      processor.connect(audioContext.destination);

      // Keep the processor active with a dummy function
      processor.onaudioprocess = () => {};

      // Ensure audio context is running
      if (audioContext.state !== 'running') {
        await audioContext.resume();
      }

      // Short delay to ensure the browser has time to establish the audio routing
      await new Promise(resolve => setTimeout(resolve, 500));

      // Initialize speech recognition from microphone (using right channel for customer voice)
      const recognitionStarted = await startAgentSpeechRecognition();
      
      // Not using separate customer speech recognition in this version
      // We're only processing the right channel of the selected microphone

      // Create media recorders if needed
      if (micStreamRef.current) {
        const options = { 
          mimeType: 'audio/webm;codecs=opus',
          audioBitsPerSecond: 128000
        };
        
        try {
          micRecorderRef.current = new MediaRecorder(micStreamRef.current, options);
          micRecorderRef.current.start();
        } catch (e) {
          console.log('Requested mime type not supported, using default instead');
          micRecorderRef.current = new MediaRecorder(micStreamRef.current);
          micRecorderRef.current.start();
        }
      }

      // Update UI state
      setIsRecording(true);
      
      // Set appropriate status message
      if (recognitionStarted) {
        setStatus('Recording in progress. Listening for customer audio on selected device.');
      } else {
        setStatus('Warning: Speech recognition failed to start, but recording is active');
      }
      
      // Clear previous data
      setTranscriptSegments([]); 
      setInterimSegments({});
      setCurrentObjection(null);
      
      // Initialize interim segment placeholders
      setInterimSegments({
        'agent-latest': {
          id: `interim-${Date.now()}-init-agent`,
          text: '',
          timestamp: new Date().toLocaleTimeString(),
          speaker: 'agent',
          isObjection: false,
          isInterim: true
        },
        'customer-latest': {
          id: `interim-${Date.now()}-init-customer`,
          text: '',
          timestamp: new Date().toLocaleTimeString(),
          speaker: 'customer',
          isObjection: false,
          isInterim: true
        }
      });

      // Start timer
      let seconds = 0;
      timerRef.current = window.setInterval(() => {
        seconds++;
        setRecordingTime(seconds);
      }, 1000);

    } catch (err) {
      console.error('Error starting recording:', err);
      setStatus(`Error: ${err instanceof Error ? err.message : 'Could not start recording'}`);
      setIsRecording(false);
      setRecognitionActive(false);
    }
  };

  // Stop all recording and speech recognition
  const stopRecording = () => {
    // Stop media recorders
    if (micRecorderRef.current) {
      micRecorderRef.current.stop();
    }
    
    if (speakerRecorderRef.current) {
      speakerRecorderRef.current.stop();
    }

    // Stop speech recognition
    if (recognitionAgentRef.current) {
      try {
        recognitionAgentRef.current.stop();
      } catch (e) {
        console.log('Error stopping agent recognition:', e);
      }
    }
    
    if (recognitionCustomerRef.current) {
      try {
        recognitionCustomerRef.current.stop();
      } catch (e) {
        console.log('Error stopping customer recognition:', e);
      }
    }
    
    // Convert any remaining interim segments to final
    Object.values(interimSegments).forEach(segment => {
      if (segment.text.trim() !== '') {
        const finalSegment: TranscriptSegment = {
          ...segment,
          id: `final-${Date.now()}-${segment.id}`,
          isInterim: false
        };
        
        // Add the segment to final transcripts
        setTranscriptSegments(prev => [...prev, finalSegment]);
      }
    });

    // Close audio context
    if (audioContextRef.current) {
      try {
        audioContextRef.current.close();
        audioContextRef.current = null;
      } catch (e) {
        console.log('Error closing audio context:', e);
      }
    }

    // Stop audio tracks
    if (micStreamRef.current) {
      micStreamRef.current.getTracks().forEach(track => track.stop());
    }
    
    if (speakerStreamRef.current) {
      speakerStreamRef.current.getTracks().forEach(track => track.stop());
    }

    // Clear timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    setIsRecording(false);
    setRecognitionActive(false);
    // Clear all interim segments
    setInterimSegments({});
    setStatus('Recording stopped');
    
    // Sort transcript segments by timestamp
    setTranscriptSegments(prev => {
      const sorted = [...prev];
      sorted.sort((a, b) => {
        // Extract timestamp from ID for sorting
        const getTime = (id: string) => {
          const matches = id.match(/\d+/);
          return matches && matches[0] ? parseInt(matches[0], 10) : 0;
        };
        return getTime(a.id) - getTime(b.id);
      });
      return sorted;
    });
  };

  // Format time for display
  const formatTime = (seconds: number): string => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="app-container mx-auto max-w-6xl p-4">

      {/* Controls Section */}
      <div className="mb-6">
        <div className="flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <div>
            {!isRecording ? (
              <button
                className="bg-red-600 hover:bg-red-700 text-white py-2 px-6 rounded-md font-medium"
                onClick={startRecording}
                disabled={!selectedMicId}
              >
                Start Recording
              </button>
            ) : (
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-6 rounded-md font-medium"
                onClick={stopRecording}
              >
                Stop Recording
              </button>
            )}
          </div>
          
          {isRecording && (
            <div className="flex items-center">
              <div className="text-lg font-mono font-semibold">
                {formatTime(recordingTime)}
              </div>
              {recognitionActive && (
                <div className="ml-3 flex items-center">
                  <div className="w-3 h-3 bg-green-500 rounded-full recording-indicator mr-2"></div>
                  <span className="text-sm text-green-600">Recognition active</span>
                </div>
              )}
              {!recognitionActive && isRecording && (
                <div className="ml-3 flex items-center">
                  <div className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></div>
                  <span className="text-sm text-yellow-600">Recognition inactive</span>
                </div>
              )}
            </div>
          )}
        </div>
        
        {/* Audio level meter - always visible */}
        <div className="mt-4">
          <p className="text-sm text-gray-600 mb-1">Customer Audio Input Level:</p>
          <AudioLevelMeter 
            stream={micStreamRef.current} 
            isRecording={isRecording} 
            onVolumeChange={() => {}} // We're not using the volume level in this version
          />
        </div>

        {/* No longer using system audio level meter as we only capture customer audio from the right channel */}
      </div>

      {/* Status indicator */}
      <div className={`p-3 rounded-lg mb-6 ${
        recognitionErrors.length > 0 
          ? 'bg-red-50 border-l-4 border-red-500 text-red-700' 
          : 'bg-gray-50 border-l-4 border-blue-500 text-gray-700'
      }`}>
        <div className="flex items-center">
          {status}
          {recognitionErrors.length > 0 && (
            <button 
              className="ml-auto text-xs px-2 py-1 rounded bg-red-100 hover:bg-red-200 text-red-800"
              onClick={() => setRecognitionErrors([])}
            >
              Clear Errors
            </button>
          )}
        </div>
        
        {/* Error list */}
        {recognitionErrors.length > 0 && (
          <div className="mt-2 text-sm">
            <details>
              <summary className="cursor-pointer">Show error details ({recognitionErrors.length})</summary>
              <ul className="mt-2 space-y-1 list-disc pl-5">
                {recognitionErrors.slice(-3).map((error, index) => (
                  <li key={index}>
                    {error.type}: {new Date(error.timestamp).toLocaleTimeString()}
                  </li>
                ))}
              </ul>
            </details>
          </div>
        )}
      </div>

      {/* Main content area - Split panels */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {/* Transcription panel */}
        <TranscriptPanel 
          segments={transcriptSegments} 
          interimSegments={Object.values(interimSegments)}
          isRecording={isRecording} 
        />
        
        {/* Suggestions panel */}
        <SuggestionsPanel 
          currentObjection={currentObjection} 
          isRecording={isRecording} 
        />
      </div>
      
      {/* Device Selection (moved below transcript panels) */}
      <div className="bg-gray-50 p-6 rounded-lg shadow-sm mb-6">
        <h2 className="text-xl font-semibold mb-4">Device Selection</h2>
        {/* Microphone selection */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Audio Input Device
          </label>
          <select
            className="w-full md:w-1/2 p-2 border border-gray-300 rounded-md"
            value={selectedMicId}
            onChange={(e) => setSelectedMicId(e.target.value)}
            disabled={isRecording}
          >
            {audioDevices.filter(device => device.kind === 'audioinput').length === 0 ? (
              <option value="">No input devices detected</option>
            ) : (
              audioDevices
                .filter(device => device.kind === 'audioinput')
                .map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label} {device.label.includes('BlackHole') ? '(Recommended for Mac)' : ''}
                    {device.label.includes('VB-Audio') ? '(Recommended for Windows)' : ''}
                  </option>
                ))
            )}
          </select>
          <p className="text-xs text-gray-500 mt-1"><strong>Important:</strong> Select the device that will capture the customer's voice. For Mac users with BlackHole, select BlackHole. For Windows users with VB-Audio, select VB-Audio Virtual Cable.</p>
        </div>
      </div>

      {/* Setup instructions section */}
      <div className="bg-gray-50 p-6 rounded-lg shadow-sm mb-6">
        <h2 className="text-xl font-semibold mb-4">Recording Setup Instructions</h2>
        
        <div className="mb-4 border border-blue-200 rounded-lg overflow-hidden">
          <button 
            className="w-full p-3 bg-blue-50 text-left flex justify-between items-center"
            onClick={() => setShowMacSetup(!showMacSetup)}
          >
            <h3 className="font-medium text-blue-800">Mac Setup</h3>
            <span className="text-blue-800 text-xl">{showMacSetup ? '−' : '+'}</span>
          </button>
          {showMacSetup && (
            <div className="p-4 bg-white">
              <p className="text-sm mb-2">To capture customer audio on macOS:</p>
              <ol className="list-decimal ml-5 text-sm space-y-1">
                <li>Install <a href="https://existential.audio/blackhole/" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">BlackHole</a> audio driver</li>
                <li>Open Audio MIDI Setup and create a Multi-Output Device with both your speakers and BlackHole</li>
                <li>Set the Multi-Output Device as your system default output</li>
                <li>Connect your headphones to your computer</li>
                <li>In Chrome, navigate to 'chrome://settings/content/microphone' and select 'BlackHole 2ch' in the dropdown</li>
                <li>In the app, select BlackHole as your input device</li>
              </ol>
            </div>
          )}
        </div>

        <div className="mb-4 border border-green-200 rounded-lg overflow-hidden">
          <button 
            className="w-full p-3 bg-green-50 text-left flex justify-between items-center"
            onClick={() => setShowWindowsSetup(!showWindowsSetup)}
          >
            <h3 className="font-medium text-green-800">Windows Setup</h3>
            <span className="text-green-800 text-xl">{showWindowsSetup ? '−' : '+'}</span>
          </button>
          {showWindowsSetup && (
            <div className="p-4 bg-white">
              <p className="text-sm mb-2">To capture customer audio on Windows:</p>
              <ol className="list-decimal ml-5 text-sm space-y-1">
                <li>Download and install <a href="https://vb-audio.com/Cable/" className="text-green-600 hover:underline" target="_blank" rel="noopener noreferrer">VB-Audio Virtual Cable</a></li>
                <li>Restart your computer after installation</li>
                <li>In Windows Audio Mixer, set <strong>CABLE Input</strong> as your default output device</li>
                <li>In Sound Control Panel → Recording tab → Right-click <strong>CABLE Output</strong> → Properties</li>
                <li>Go to the Listen tab, check "Listen to this device" and select your speakers or headphones</li>
                <li>In Chrome, navigate to 'chrome://settings/content/microphone' and select 'VB-Cable' in the dropdown</li>
                <li>In ALFRED_, select <strong>CABLE Output</strong> as your input device</li>
              </ol>
            </div>
          )}
        </div>


      </div>
      
      {/* Footer */}
      <footer className="border-t mt-10 pt-6 pb-4 text-center text-sm text-gray-600">
        <p>ALFRED_ &copy; Rep Machina Inc. 2025</p>
      </footer>
    </div>
  );
};

export default App;