import React from 'react';
import { Objection } from './apiService';
import { useScript } from './ScriptContext';

interface SuggestionsPanelProps {
  currentObjection: Objection | null;
  isRecording: boolean;
}

const SuggestionsPanel: React.FC<SuggestionsPanelProps> = ({ currentObjection, isRecording }) => {
  const { currentScript, isLoading, refreshScript } = useScript();

  // Group objections by category
  const objectionsByCategory: Record<string, Objection[]> = {};
  
  if (currentScript?.objections) {
    currentScript.objections.forEach(obj => {
      const category = obj.category || 'General';
      if (!objectionsByCategory[category]) {
        objectionsByCategory[category] = [];
      }
      objectionsByCategory[category].push(obj);
    });
  }

  return (
    <div className="panel bg-white rounded-lg shadow border border-gray-200 h-full flex flex-col">
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold text-gray-800">
            ALFRED_'s Suggestions
          </h3>
          {currentScript && (
            <div className="mt-1 text-sm text-gray-600">
              <p>
                <span className="font-medium">Script:</span> {currentScript.name}
              </p>
              {currentScript.description && (
                <p className="text-xs mt-1 italic">{currentScript.description}</p>
              )}
            </div>
          )}
        </div>
        <button 
          onClick={() => refreshScript()} 
          className="text-xs text-blue-600 hover:text-blue-800"
          title="Refresh script data"
        >
          Refresh
        </button>
      </div>

      <div className="flex-grow overflow-y-auto p-4">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-pulse text-gray-500">Loading script...</div>
          </div>
        ) : !isRecording ? (
          <div className="text-gray-500 italic">
            Start recording to see AI suggestions here.
          </div>
        ) : currentObjection ? (
          <div className="bg-blue-50 p-4 rounded-lg border-l-4 border-blue-500">
            <h4 className="font-semibold text-blue-800 mb-2">
              Detected Objection
              {currentObjection.category && (
                <span className="ml-2 text-xs bg-blue-200 text-blue-800 px-2 py-1 rounded">
                  {currentObjection.category}
                </span>
              )}
            </h4>
            <p className="text-blue-900">{currentObjection.rebuttal}</p>
          </div>
        ) : (
          <div className="text-gray-500">
            No objections detected. ALFRED_ is listening...
          </div>
        )}

        {/* Show available objections/rebuttals grouped by category */}
        {isRecording && currentScript && currentScript.objections && currentScript.objections.length > 0 && (
          <div className="mt-6">
            <h4 className="font-medium text-gray-700 mb-2 text-sm">
              Common Objections by Category:
            </h4>
            <div className="space-y-4 text-sm">
              {Object.entries(objectionsByCategory).map(([category, objections]) => (
                <div key={category} className="bg-gray-50 rounded-lg p-2">
                  <h5 className="font-medium text-gray-800 mb-2 border-b border-gray-200 pb-1">
                    {category} ({objections.length})
                  </h5>
                  <div className="space-y-2">
                    {objections.map((objection: Objection) => (
                      <div 
                        key={objection.id}
                        className="p-2 rounded hover:bg-gray-100 transition-colors cursor-pointer"
                      >
                        <p className="text-gray-600 text-xs">{objection.rebuttal}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestionsPanel;